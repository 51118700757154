/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './AccountActions.css'

import React from 'react'
import { Popover } from 'antd'
import { observer, inject } from 'mobx-react'
import { Collapse } from 'react-collapse'
import className from 'classnames'
import Desktop from '../display/Desktop'
import NotDesktop from '../display/NotDesktop'
import Avatar from '../avatar'
import { sidebar } from '../sidebar'
import Notification from './assets/Notification.svg'
import NotificationWhite from './assets/NotificationWhite.svg'
import logoutWhite from './assets/logout-white.svg'
import profileWhite from './assets/profile-white.svg'
import close from './assets/close-white.svg'
import ArrowDownGrey from './assets/ArrowDownGrey.svg'
import arrowDownWhite from './assets/arrow-down-white.svg'
import Profile from "./assets/Profile.svg";
import Button from "../button";
import LogoutWhite from "./assets/LogoutWhite.svg";


@inject("store")
@observer
class AccountActions extends React.Component {

  renderDetails = () => {
    const { store, inverted } = this.props
    const {
      authStore: { hasToken },
      meta: {currentUser: current_user},
      view: {openUserablePageForUser},
      logout
    } = store
    if (!hasToken) return null

    const { fullName, attributes, gender, photo_url } = current_user
    const detailsClass = className(
      'touchable-opacity flex-row',
      { 'flex-row--reverse': inverted }
    )

    const details = (
      <div onClick={sidebar.toggleUserPane} className={detailsClass}>
        {inverted && (
          <img
            src={sidebar.isUserPaneVisible ? close : arrowDownWhite}
            alt=""
            style={{ width: 8 }}
          />
        )}
        <div className="accountActions-details">
          <div className="accountActions-fullname">{fullName}</div>
          <div className="text-small text--grey text--italic">{attributes.email}</div>
        </div>
        <Avatar
          small
          src={photo_url}
          sex={gender}
          bordered
          borderColor={inverted ? 'white' : null}
        />
        {inverted || <img src={ArrowDownGrey} className="utils-append" alt="" />}
      </div>
    )
    const popover = (
        <>
            <div
                onClick={() => {openUserablePageForUser(current_user)}}
                className="touchable-opacity utils-fullwidth flex-row"
            >
                <img src={Profile} className="accountActionsPopover-icon" alt="" />
                My Profile
            </div>
            <div className="divider" style={{ margin: '10px 0' }} />
            <Button size="small" className="text-p" onClick={logout} fullwidth>
                <img src={LogoutWhite} className="accountActionsPopover-icon" alt="" />
                Logout
            </Button>
        </>
    )
      
    return (
      <>
        <Desktop>
          <Popover
            content={popover}
            trigger="click"
            placement="bottomRight"
            overlayClassName="override-popover"
          >
            {details}
          </Popover>
        </Desktop>
        <NotDesktop>{details}</NotDesktop>
      </>
    )
  }

  render() {
    const { inverted, store } = this.props
    const { logout, meta: {currentUser}, view: {openUserablePageForUser} } = store
    const accountActionsClassName = className(
      'accountActions',
      'flex-row flex-spaceBetween',
      { 'accountActions--inverted': inverted }
    )

    return (
      <>
        <div className={accountActionsClassName}>
          <div className="accountNotification accountNotification-hasBadge touchable-opacity">
            {inverted && <img src={NotificationWhite} alt="" />}
            {inverted || <img src={Notification} alt="" />}
          </div>
          {this.renderDetails()}
        </div>
        <NotDesktop>
          <Collapse isOpened={sidebar.isUserPaneVisible}>
            <div className="accountActions-collapse">
              <div
                  onClick={() => {openUserablePageForUser(currentUser)}}
                  className="accountActions-collapseItem flex-row flex-alignCenter touchable-opacity"
              >
                <img src={profileWhite} alt="" />
                My Profile
              </div>
              <div
                onClick={logout}
                className="accountActions-collapseItem flex-row flex-alignCenter touchable-opacity"
              >
                <img src={logoutWhite} alt="" />
                Logout
              </div>
            </div>
          </Collapse>
        </NotDesktop>
      </>
    )
  }
}

export default AccountActions