import * as pkg from '../../package.json'


export const TESTENV = 'TESTENV'
export const PRODENV = 'PRODENV'
export const APP_VERSION = pkg.version
export const CURRENTENV = process.env.BLUEBIC_ENV ? process.env.BLUEBIC_ENV : 'PRODENV'
export const DEMO_DOMAIN_URL = 'demo.bluebic.com'
export const DEMO_DOMAIN_REGEX_LIST = [/(.)+\.netlify\.com/, /localhost/]
export const BASE_URL = 'https://api.bluebic.com'
export const BASE_URL_LOCALHOST = 'http://localhost:3001'
export const PAYSTACK_TEST_PUBLIC_KEY = 'pk_test_c8a6a4b18fe7efe1fc26e1f44352a844b878d8b7'
export const PAYSTACK_PUBLIC_KEY = 'pk_live_5d1df09e9ca38211df8342f3773d0b4ee1d46594'
export const HELPSCOUT_BEACON_ID = '330892b1-6464-47e6-8893-48dafb89584a'